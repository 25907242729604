.home-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.home-container .left-panel {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
}

.home-container .left-panel div {
    font-size: 12em;
    color: white;
}

.home-container .left-panel span {
    display: block;
    position: relative;
    margin-left: 30px;
    line-height: 1.6;
}

.home-container .left-panel span::before {
    content: " ";
    position: absolute;
    background: white;
    width: 2px;
    top: 20px;
    bottom: 20px;
    left: -30px;
}

.home-container .left-panel p {
    display: block;
    font-size: 1em;
    color: white;
}

.home-container .right-panel {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.home-container .right-panel img {
    display: flex;
    width: 60%;
    height: auto;
    border-radius: 50%;
    border: solid white;
}