.parallax-wallpaper {
  position: absolute;
  width: 120%;
  height: 120%;
  backface-visibility: hidden;
  background-position-x: center;
  background-position-y: center;
  background-image: url("../../images/code-wallpaper.png");
  background-repeat: repeat;
  background-size: contain;
  opacity: 0.15;
  z-index: -100;
}
