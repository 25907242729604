.resume-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.resume-container object {
    display: flex;
    width: 100%;
    height: 100%;
    border: none;
}