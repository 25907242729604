.notfound-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.notfound-container h2 {
    display: flex;
    color: white;
}

.notfound-container h3 {
    display: flex;
    color: white;
    text-decoration: none;
}