.footer-container {
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10%;
    padding-top: 30px;
}

.footer-container .social-links {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-container .fa {
    text-align: center;
    color: #efefef;
    text-decoration: none;
    text-shadow: .15rem .15rem #091d2b;
    font-size: 48px;
    margin: 5px;
}

.footer-container .fa:hover {
    color: #c2c2c2;
}

.footer-container .resume-icon {
    position: relative;
    content:url('../../images/resume-icon.png');
    margin: 5px;
    vertical-align: middle;
    height: 48px;
}

.resume-icon:hover {
    content:url('../../images/resume-icon-hover.png');
}

.footer-container .credit {
    color: white;
    margin: 10px;
}