.navbar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 15%;
}

.navbar-container div {
    margin: 24px;
    color: white;
    font-size: 24px;
    text-transform: uppercase;
    text-decoration: none;
}

.navbar-container a {
    text-decoration: none;
}